import * as React from "react";

import Footer from "../components/footer";
import Preise from "../components/preise";
import Header from "../components/header";
import Testimonial from "../components/testimonial";
import { Helmet } from "react-helmet";
import { DemoLink, KontaktLink } from "../components/links";

// @ts-ignore
import Video from "../../assets/videos/captis.mp4";
// @ts-ignore
import LambdaLogo from "../../assets/images/lambda9-standalone.svg";
// @ts-ignore
import WomanJpg from "../../assets/images/women-3118387-gespiegelt.jpg";
// @ts-ignore
import CaptisLogo from "../../assets/images/lambda-captis.svg";
// @ts-ignore
import FormularErstellen from "../../assets/images/formular-erstellen.png";
// @ts-ignore
import FormularAusfuellen from "../../assets/images/formular-ausfuellen.png";
import { useEffect, useState } from "react";
import TestimonialList from "../components/testimonialList";

const textStyle: React.CSSProperties = {
  fontSize: "1.1rem",
  lineHeight: "1.5",
};

// markup
const IndexPage = () => {
  return (
    <div className="container-fluid">
      <Helmet>
        <title>Online-Formulare einfach | captis</title>
        <meta charSet="utf-8" />
      </Helmet>
      <div className="content">
        <div className="row">
          <header id="header" className="col-12">
            <Header />
          </header>
        </div>
      </div>
      <div className="content">
        <section className="row mt-6">
          <div className="col-md-4">
            <div
              className="d-flex flex-column justify-content-center"
              style={textStyle}
            >
              <h1 className="fw-bold">
                Mit uns erstellen Sie online Formulare schnell und sicher.
              </h1>
              <p className="mt-3" style={textStyle}>
                Unternehmen, Vereine und Einzelpersonen aller Art und Größe
                digitalisieren mit <em>captis</em> Anmeldeformulare, Checklisten
                und jegliche Form von Datenerfassung - ganz ohne Word und teure
                IT-Dienstleister.
              </p>
              <DemoLink />
              <div className="mt-3">
                <KontaktLink />
              </div>
            </div>
          </div>
          <div className="col-md-8 mt-md-0 mt-5">
            <div className="ps-4">
              <img src={FormularErstellen} className="shadow-lg" />
            </div>
          </div>
        </section>
      </div>
      <div className="content">
        <div className="row mt-7 mb-5">
          <div className="col-md-12">
            <div
              className="flex mt-2 flex-wrap"
              style={{ display: "flex" }}
              id="captis--stats--container"
            >
              <div className="stats-container">
                <div className="stats-number fw-bold" id="captis--mandanten">
                  86
                </div>
                <div className="stats-label" id="captis--label--mandanten">
                  Kunden
                </div>
              </div>
              <div className="stats-container">
                <div className="stats-number fw-bold" id="captis--benutzer">
                  282
                </div>
                <div className="stats-label" id="captis--label--benutzer">
                  Benutzer
                </div>
              </div>
              <div className="stats-container">
                <div className="stats-number fw-bold" id="captis--erfassungen">
                  15.664
                </div>
                <div className="stats-label" id="captis--label--erfassungen">
                  Erfassungen
                </div>
              </div>
              <div className="stats-container">
                <div className="stats-number fw-bold" id="captis--vorlagen">
                  528
                </div>
                <div className="stats-label" id="captis--label--vorlagen">
                  Formulare
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-light full-width mt-6" style={{ minHeight: "800px" }}>
        <div className="content pt-5 pb-5">
          <h3 className="fw-bold">Wie funktioniert das?</h3>
          <div className="row mt-5">
            <div className="col-md-8 mt-md-0 mt-5 pe-md-5">
              <video
                id="video2"
                controls
                preload="auto"
                width="100%"
                className="shadow-lg"
              >
                <source id="videoSource2" src={Video} type="video/mp4" />
                Ihr Browser unterstützt leider keine eingebetteten Videos. Kein
                Problem, laden Sie sich das Produktvideo zu captis einfach{" "}
                <a href={Video}>hier</a> herunter.
              </video>
            </div>
            <div className="col-md-4">
              <div
                className="d-flex flex-column justify-content-center"
                style={{ maxWidth: "420px" }}
              >
                <div className="mt-3">
                  <div>
                    <h4 className="fw-bold">1. Formular erstellen</h4>
                    <p style={textStyle}>
                      Sie erstellen ein Formular, zum Beispiel für ein
                      Lastschriftmandat. Dabei können Sie verschiedene Regeln,
                      wie Pflichtangaben oder die maximale Länge von Eingaben,
                      definieren.
                    </p>
                  </div>
                  <div className="mt-4">
                    <h4 className="fw-bold">2. Teilen</h4>
                    <p style={textStyle}>
                      Sie teilen einen vom System erstellten Link oder QR-Code
                      zu Ihrem Formular mit den relevanten Personen. Oder Sie
                      binden das Formular direkt auf Ihrer Homepage ein.
                    </p>
                  </div>
                  <div className="mt-4">
                    <h4 className="fw-bold">3. Daten verarbeiten</h4>
                    <p style={textStyle}>
                      Sie exportieren die eingetragenen Daten als PDF, CSV oder
                      sehen sie direkt in captis ein, um sie zu verarbeiten.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content pt-5 pb-5">
        <h2 className="mt-4 fw-bold mb-5">Warum captis?</h2>
        <div className="row mt-7">
          <div className="col-md-3 d-flex justify-content-md-center">
            <div className="advantage">
              <div className="d-flex flex-md-column">
                <i className="fas fa-microchip align-self-center align-self-md-start"></i>
                <div className="mt-md-2 ms-2 ms-md-0 fw-bold">
                  Weniger Arbeit
                </div>
              </div>
              <p className="mt-2">
                Kein Entziffern von Handschriften. Keine fehlenden
                Pflichtangaben. Weniger Fehler bei der Übertragung in Ihre
                IT-Systeme. So konzentrieren Sie sich auf Ihre Kernkompetenz.
              </p>
            </div>
          </div>
          <div className="col-md-3 d-flex justify-content-md-center">
            <div className="advantage">
              <div className="d-flex flex-md-column">
                <i className="fas fa-euro-sign align-self-center align-self-md-start"></i>
                <div className="mt-md-2 ms-2 ms-md-0 fw-bold">
                  Weniger Kosten
                </div>
              </div>
              <p className="mt-2">
                Sie reduzieren Ihre Brief- und Papierkosten. Mit weniger Arbeit
                passieren auch weniger Fehler. So schonen Sie nicht nur die
                Umwelt, sondern auch Ihre Finanzen.
              </p>
            </div>
          </div>
          <div className="col-md-3 d-flex justify-content-md-center">
            <div className="advantage">
              <div className="d-flex flex-md-column">
                <i className="fas fa-clock align-self-center align-self-md-start"></i>
                <div className="mt-md-2 ms-2 ms-md-0 fw-bold">Mehr Zeit</div>
              </div>
              <p className="mt-2">
                Informationen eines ausgefüllten Formulars stehen Ihnen in
                Echtzeit zur Verfügung. Kein Warten auf das Einreichen oder die
                Zusendung eines ausgefüllten Formulars. Mehr Zeit für die
                wichtigen Dinge.
              </p>
            </div>
          </div>
          <div className="col-md-3 d-flex justify-content-md-center">
            <div className="advantage">
              <div className="d-flex flex-md-column">
                <i className="fas fa-shield-alt align-self-center align-self-md-start"></i>
                <div className="mt-md-2 ms-2 ms-md-0 fw-bold">Datenschutz</div>
              </div>
              <p className="mt-2">
                Unsere gesamte Datenverarbeitung richtet sich nach
                internationalen Standards wie ISO 27001 und der DSGVO.
                Zusätzlich sind alle erfassten Daten in unseren Datenbanken
                verschlüsselt und somit <em>nur Ihnen</em> zugänglich.
              </p>
            </div>
          </div>
        </div>
      </div>
      <TestimonialList />
      <div className="content mt-7 mb-6">
        <div className="row">
          <div className="col-md-3 d-flex flex-column justify-content-end">
            <h3 className="fw-bold">Bereit zum Starten?</h3>
            <p style={{ ...textStyle, maxWidth: "400px" }}>
              Mit <em>captis</em> haben Sie alles, was Sie zum digitalisieren
              Ihrer Formulare benötigen. Machen Sie es sich und Ihren Kunden
              einfacher, Formulare auszufüllen und digitalisieren Sie auch ohne
              Informatikstudium Ihre Prozesse.
            </p>
            <DemoLink />
            <div className="mt-3">
              <KontaktLink />
            </div>
          </div>
          <div className="col-md-9">
            <img src={WomanJpg} />
          </div>
        </div>
      </div>
      <div className="bg-light full-width">
        <div className="content">
          <Preise />
        </div>
      </div>
      <div className="content mt-6 mb-5">
        <h2 className="fw-bold">Kontakt</h2>
        <div className="row">
          <iframe
            id="foo"
            src="https://capt.is/formular/S8Fp5F5DcPB1VuZxMVsX4JI0L6SBLp"
            title="kontakt"
            className="w-100"
            style={{ minHeight: "1300px" }}
          ></iframe>
          <div className="centerText captis-form">
            <noscript>
              JavaScript muss aktiviert sein, damit der Konfigurator
              funktioniert.
            </noscript>
          </div>
        </div>
      </div>
      <div className="bg-light full-width">
        <div className="content">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
