import React, { useEffect, useState } from "react";
import Testimonial from "./testimonial";

// @ts-ignore
import EngelUndFischbachLogo from "../../assets/images/engel_und_fischbach_logo.png";
//@ts-ignore
import EnerixLogo from "../../assets/images/enerix_logo.png";
// @ts-ignore
import NozLogo from "../../assets/images/noz_logo.png";
// @ts-ignore
import ReximLogo from "../../assets/images/rexim_logo.png";
// @ts-ignore
import WiregLogo from "../../assets/images/wireg_logo.png";
// @ts-ignore
import VanDeVelde from "../../assets/images/van_de_velde_logo.png";

const testimonials = [
  {
    url: "https://rexim.de",
    name: "Rexim Lebensmittelproduktion KG",
    logo: ReximLogo,
    quote:
      "Die 3G-Nachweispflicht am Arbeitsplatz stellte uns als Mittelstandsunternehmen vor die problematische Herausforderung, quasi von heute auf morgen ein praktikables System zur Sammlung, Kontrolle und zur datenschutzkonformen Speicherung von 3G-Nachweisen unserer Belegschaft zu finden. captis hält genau dafür eine schlanke Lösung bereit, die schnell eingerichtet ist und seit dem ersten Tag einfach funktioniert.",
    author: "Kevin Jonas (Justiziar)",
  },
  {
    url: "https://noz.de",
    name: "NEXT NOZ Expert Team GmbH & Co. KG",
    logo: NozLogo,
    quote:
      "Schnell, zuverlässig und innovativ – so würde ich die lambda9 beschreiben. Ein Anruf genügt und schon kümmert sich jemand um das Thema. Wie alle anderen Unternehmen standen wir auch vor der Herausforderung die 3G-Nachweise der Mitarbeitenden zu dokumentieren und eingereichte Nachweise zu prüfen. Wir haben diese Herausforderung souverän mit captis gemeistert, recht zügig und im Betrieb äußerst geräuschlos läuft seit dem das Thema vor sich hin.",
    author: "Tanja Wilhelm (Leitung Personal-Abrechnung & Service)",
  },
  {
    url: "https://ravdvelde.de",
    name: "van de Velde Rechtsanwälte",
    logo: VanDeVelde,
    quote:
      "Wir sind Rechtsanwälte. Für uns steht Datenschutz und Datensicherheit an oberster Stelle. Die Daten unserer Mandanten sind sensibel, deswegen brauchen wir das bestmögliche Sicherheitskonzept. Mit captis haben wir genau das richtige Werkzeug für die Umsetzung dieses Sicherheitskonzepts. Und es erleichtert auch noch unsere tägliche Arbeit. Wir sind sehr froh, lambda9 als Partner an unserer Seite zu haben. Besonders herausragend ist die außergewöhnlich gute Erreichbarkeit. Wenn mal ein Problem auftritt, wird es unverzüglich gelöst. Wir können captis uneingeschränkt empfehlen. Es löst Probleme, macht die Abläufe digitaler. Und es bringt einfach Spaß, damit zu arbeiten!",
    author: "André van de Velde (Rechtsanwalt)",
  },
  {
    url: "https://wireg.de",
    name: "WiREG Flensburg/Schleswig mbH",
    logo: WiregLogo,
    quote:
      "Persönlich, unbürokratisch und innovativ. Besonders hervorheben möchte ich die unbürokratische Erreichbarkeit, kurze Reaktionszeit und den sehr menschlichen und persönlichen Kontakt zu unseren Ansprechpartnern bei der lambda9. Die Vorgabe zur 3G-Nachweispflicht von Arbeitgebern hat uns, wie viele andere auch, ziemlich unvorbereitet erwischt, aber wir konnten in kürzester Zeit mit captis dazu eine Lösung etablieren, die nach der Produktivstellung einfach vor sich hin läuft; eine echte Arbeitserleichterung.",
    author: "Michael Otten (Geschäftsführer)",
  },
  {
    url: "https://www.engel-fischbach.de",
    name: "Engel & Fischbach GmbH",
    logo: EngelUndFischbachLogo,
    quote:
      "Mit der Einführung von captis sind bei uns einige Abläufe optimiert worden. Wir konnten unsere Prozesse deutlich beschleunigen bei zeitgleicher Sicherstellung der Aspekte der DSGVO. Richtig gut mit guten Lösungen zu arbeiten und mit der lambda9 einen kompetenten Partner mit einem offenen Ohr für Verbesserungen und Weiterentwicklung zu haben.",
    author: "René Engel (Geschäftsführer)",
  },
  {
    url: "https://www.nordostseesolar.de",
    name: "Enerix Nord-Ostsee Solar GmbH",
    logo: EnerixLogo,
    quote:
      "Als Fachbetrieb für Photovoltaik sind uns klima- und umweltfreundliche Arbeitsabläufe ein großes Anliegen. Dank captis konnten wir nicht nur unsere Prozesse stark vereinfachen, sondern auch auf ein nahezu papierloses Büro umstellen; von der Auftragserteilung bis zur Abnahmedokumentation wickeln wir inzwischen alles über captis-Formulare ab. Das Team der lambda9 steht uns dabei mit schnellem und freundlichem Support zur Seite.",
    author: "Boris Grundl (Geschäftsführer)",
  },
];

export default function TestimonialList() {
  const [slide, setSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setSlide((slide + 1) % testimonials.length);
    }, 8000);
    return () => clearInterval(timer);
  }, [slide]);

  const activeSlideshowDot = "captis__slideshow-dot captis__slideshow-active";
  const slideDot = "captis__slideshow-dot";

  return (
    <div
      className="bg-light full-width pt-1 pb-5"
      style={{ minHeight: "600px" }}
    >
      <div className="content mt-7">
        <h2 className="mt-4 fw-bold mb-5">Unsere Kunden</h2>
        <div className="row">
          <div className="offset-md-3 col-md-8 mb-3">
            <div className="captis__slideshow-dots text-center">
              {testimonials.map((t, i) => (
                <div
                  className={slide == i ? activeSlideshowDot : slideDot}
                  onClick={() => setSlide(i)}
                >
                  <div className="captis__slideshow-dot-img-container">
                    <img
                      className="captis__slideshow-dot-img align-self-center"
                      src={t.logo}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {testimonials.map((t, i) => (
              <Testimonial
                slide={slide}
                key={i}
                id={i}
                url={t.url}
                name={t.name}
                logo={t.logo}
                quote={t.quote}
                author={t.author}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
