import React from "react";

const textStyle: React.CSSProperties = {
  fontSize: "1.1rem",
  lineHeight: "1.5",
};

export default function Testimonial(props: {
  slide: number;
  id: number;
  url: string;
  name: string;
  logo: string;
  author: string;
  quote: string;
}) {
  return (
    <div
      className="row captis__slides captis__slideshow-fade"
      style={{ display: props.slide == props.id ? "flex" : "none" }}
    >
      <div className="col-md-4 d-flex flex-column justify-content-center">
        <a target="_blank" className="link align-self-center" href={props.url}>
          <img src={props.logo} className="captis__testimonials-logo-img" />
        </a>
        <p className="mt-2 text-center">{props.name}</p>
      </div>
      <div className="col-md-6 captis__testimonials-text-wrapper">
        <div style={textStyle}>
          <em>„{props.quote}“</em>
        </div>
        <p className="mt-2">–&nbsp;{props.author}</p>
      </div>
    </div>
  );
}
